import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Text } from '@/components/base/text';
import { Box } from '@/components/base/box';

const OverlayWrapper = styled.div`
  width: 308px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textGray20};
  border-radius: ${({ theme }) => theme.space.xs}px;
`;

const OverlayHeader = styled.div`
  padding: ${({ theme }) => theme.space.s}px;
  background-color: ${({ theme }) => theme.colors.modalFooter};
  border-bottom: 1px solid ${({ theme }) => theme.colors.disabled};
`;

export const Overlay: FC<{
  title: string;
  children: JSX.Element[] | JSX.Element | string;
}> = ({ title, children }) => {
  return (
    <OverlayWrapper>
      <OverlayHeader>
        <Text variant="2xs" color="textGray20">
          {title}
        </Text>
      </OverlayHeader>
      <Box padding="s" width="308px">
        {children}
      </Box>
    </OverlayWrapper>
  );
};
