import {
  ComponentProps,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { position } from 'styled-system';
import Image from 'next/legacy/image';

import { Box } from '@/components/base/box';
import { Flex } from '@/components/base/flex';
import { Text } from '@/components/base/text';

type FlexProps = ComponentProps<typeof Flex>;
type BoxProps = ComponentProps<typeof Box>;

// global styles that get applied when the modal is open
const modalOpenGlobalStyles = css`
  html {
    overflow: hidden;
  }
  body {
    overflow: hidden;
  }
`;

// this is shared by all components that contain content to maintain consistent padding
const contentPaddingProp: FlexProps['p'] = ['s', 's', 's'];

const fullScreenProps: Partial<FlexProps> = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const ModalFlexWrapper = styled(Flex)`
  ${position}
`;

export type ModalProps = FlexProps & {
  ref?: HTMLDivElement;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  modalPosition?: 'center' | 'top';
  /**
   * Defines which key or keys will close this modal. "Escape" by default
   */
  closeModalKeybinds?: string | string[];
};

export type ModalContextType = Pick<ModalProps, 'isOpen'> & {
  toggleModal: () => void | Promise<void>;
  openModal: () => void | Promise<void>;
  closeModal: () => void | Promise<void>;
};

export const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType,
);

export const Modal: FC<ModalProps> = ({
  closeModalKeybinds = 'Escape',
  ...props
}) => {
  const { isOpen, setIsOpen, modalPosition } = props;
  const hideOverflow = true;
  const closeKeybindList =
    typeof closeModalKeybinds === 'string'
      ? [closeModalKeybinds]
      : closeModalKeybinds;

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const toggleModal = useCallback(() => {
    setIsOpen((previousValue) => !previousValue);
  }, [setIsOpen]);

  // function used as listener for `keydown` event
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      for (const keyName of closeKeybindList) {
        if (event.key === keyName) {
          setIsOpen(false);
          return;
        }
      }
    },
    [setIsOpen],
  );

  // this effect adds / removes the `keydown` event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeydown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isOpen]);

  const context = useMemo(
    () => ({
      closeModal,
      openModal,
      toggleModal,
      isOpen,
    }),
    [closeModal, openModal, toggleModal, isOpen],
  );

  return (
    <ModalContext.Provider value={context}>
      {isOpen && hideOverflow && <Global styles={modalOpenGlobalStyles} />}

      {isOpen && (
        <ModalFlexWrapper
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          {...fullScreenProps}
          flexDirection="column"
          justifyContent={modalPosition === 'top' ? 'normal' : 'center'}
          alignItems="center"
          backgroundColor="transparent"
          zIndex="notificationLow"
          {...props}
        />
      )}
    </ModalContext.Provider>
  );
};

export type ModalOverlayProps = BoxProps & {
  handleClick?: () => void | Promise<void>;
};

const ModalOverlayWrapper = styled(Box)`
  ${position}
`;

export const ModalOverlay: FC<ModalOverlayProps> = (props) => {
  const { handleClick } = props;
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalOverlayWrapper
      {...fullScreenProps}
      backgroundColor="overlayBGColor"
      opacity={0.8}
      zIndex="notificationLow"
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
        closeModal();
      }}
      {...props}
    />
  );
};

export type ModalContentProps = FlexProps & {
  includeVerticalPadding?: boolean;
  allowOverflow?: boolean;
};

export const ModalContent: FC<ModalContentProps> = (props) => {
  return (
    <Box
      zIndex="notificationLow"
      maxWidth={['100%', '100%', '75%']}
      width={['100%', '100%', 'auto']}
      maxHeight={['100%', '100%', '75%']}
      height={['100%', '100%', 'auto']}
      position="relative"
      flexDirection="column"
      backgroundColor="secondary"
      overflowY={props.allowOverflow ? 'visible' : 'auto'}
      {...props}
    />
  );
};

export const ModalTitle: FC<ModalTitleProps> = (props) => (
  <Text id="modal-title" variant="xsBold" {...props} />
);

type ModalHeaderProps = FlexProps & {
  children: JSX.Element[] | JSX.Element;
};
export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Flex
      flexDirection="column"
      padding={['s']}
      width="100%"
      position="relative"
      minHeight="49px"
      backgroundColor="modalHeader"
      color="secondary"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export type ModalBodyProps = FlexProps;

export const ModalBody: FC<ModalBodyProps> = (props) => (
  <Flex
    marginBottom={contentPaddingProp}
    paddingX={contentPaddingProp}
    flexGrow={1}
    {...props}
  />
);

export type ModalFooterProps = FlexProps;

export const ModalFooter: FC<ModalFooterProps> = (props) => (
  <Flex
    padding="m"
    backgroundColor="modalFooter"
    borderTop={1}
    borderTopStyle="solid"
    borderTopColor="lightBorder"
    {...props}
  />
);

type ModalTitleProps = ComponentProps<typeof Text>;

type ModalCloseButtonProps = {
  hoverBackgroundColor?: string;
  right?: FlexProps['p'];
  top?: FlexProps['p'];
  iconWidth?: number;
  iconHeight?: number;
  onClose?: () => void;
};
const ModalCloseButtonWrapper = styled(Flex)<
  ModalCloseButtonProps & FlexProps & React.HTMLProps<HTMLButtonElement>
>`
  padding: ${({ theme }) => theme.space['2xs']}px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: ${({ theme, hoverBackgroundColor }) =>
      hoverBackgroundColor ?? theme.colors.closerHover};
  }
`;

export const ModalCloseButton = (props: ModalCloseButtonProps) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalCloseButtonWrapper
      position="absolute"
      right={props.right ?? contentPaddingProp}
      top={props.top ?? contentPaddingProp}
      onClick={props.onClose ?? closeModal}
      as="button"
      type="button"
      hoverBackgroundColor={props.hoverBackgroundColor}
    >
      <Image
        src="/images/close-lg.svg"
        alt="close modal"
        width={props.iconWidth ?? 15}
        height={props.iconHeight ?? 15}
        priority={true}
      />
    </ModalCloseButtonWrapper>
  );
};
